.signup__container{
    display: flex;
    position: relative;
}
.signup__form{
    width: 65%;
}
.form__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 45px;
}

.signup_step-section{
    position: absolute;
    top: 0;
    right: 0;
    background-color: var( --light-grey);
    width: 34%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top:4%;
}

.buttons_container{
    display: flex;
    width: 100%;
    margin-bottom: 45px;
}

@media(max-width: 1024px){
    .signup_step-section{
        display: none;
    }
    .signup__form{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .form__container{
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 500px){
    .buttons_container{
        width:100%;
        align-items: center;
        height: 130px;
    }
}

.email-marketing__field{
    margin-bottom:20px;
    gap: 6px;
    display:flex;
    align-items:center;
    justify-content: space-between;
    margin-bottom:45px;
}


