.avatar__container{
    margin-bottom: 45px;
}

.avatar__title{
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.4px;
    color: #22333B;
}
.avatar__image-form{
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.avatar__photo{
    width: 97px;
    height: 97px;
    object-fit: cover;
    border-radius: 50%;
}

.avatar__input-file[type='file']{
    color: rgba(0, 0, 0, 0);
    margin-left: 25px;
}

@media (max-width: 850px){
    .avatar__container{
        width: 320px;
    }
    .avatar__input-file{
        width: 30%;
    }
}
@media (max-width: 360px){
    .avatar__container{
        width: 280px;
    }
    .avatar__input-file{
        width: 40%;
    }
}