.sidebar__container{
    flex-basis:25%;
}

@media(max-width:850px){
    .sidebar__container{
        flex-basis:100%;
        overflow: auto;
        white-space: nowrap;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .sidebar__container::-webkit-scrollbar {
        display: none;
    }
    
    /* Hide scrollbar for IE, Edge and Firefox */
    .sidebar__container {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}


.blocco-menu__container{
    width: 244px;
    min-height: 200px;
    margin-bottom: 60px;
}

@media(min-width:1024px){
    .blocco-menu__container{
        flex-basis:25%;
        
    }
}

@media(max-width:850px){
    .blocco-menu__container{
        min-height: 60px;
        width: 100%; 
        margin-top: 20px;
        margin-bottom: 45px; 
        overflow:auto;
        overflow-x: hidden;
        white-space:nowrap;
        flex-basis:100%;
    }
}




.blocco-menu__list{
    width: 100%;
    padding-left: 0;
    cursor: pointer;
} 
@media (max-width: 850px){
    .blocco-menu__list{
        display: flex;
        padding-left:0;
    }
}

.blocco-menu__list-item{
    margin-top: 8px;
    list-style: none;
    padding: 8px;
}

.blocco-menu__list-item a{
    font-size: 18px;
    line-height: 25px;
    text-align: justify;
    color: #22333B;
    text-decoration:none;
}

@media (max-width: 850px){
    .blocco-menu__list-item a{
        color: #22333B;
        text-align: center;    
    }
}

@media(max-width:500px){
    .blocco-menu__list-item a{
        font-size: 16px;
        line-height: 20px;
    }
}

@media (max-width: 850px){
    .blocco-menu__list-item{
        margin: 0px 10px 0px 10px;
        margin-top: 0;
        border: none;
        padding: 15px 30px;
        background-color: #F6F6F6;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;    
    }
}

@media(max-width:500px){
    .blocco-menu__list-item{
        padding:8px 15px;
    }
}

.block-activate a{
    font-weight: bold;
    border-left: 2px solid #EF4444;
    padding-left: 10px;
}

@media (max-width: 850px){
    .block-activate{
        background-color: #EF4444 !important;
        color: white !important;
    }
     
 }