.signup__container{
    display: flex;
    position: relative;
}
.signup__form{
    width: 65%;
}
.form__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 45px;
}

.signup_step-section{
    position: absolute;
    top: 0;
    right: 0;
    background-color: var( --light-grey);
    width: 34%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top:4%;
}

.buttons_container{
    display: flex;
    width: 100%;
    margin-bottom: 45px;
}

@media(max-width: 1024px){
    .signup_step-section{
        display: none;
    }
    .signup__form{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .form__container{
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 500px){
    .buttons_container{
        width:100%;
        align-items: center;
        height: 130px;
    }
}

.grey__subtitle-top{
    margin-top:70px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #6f6f6f;
    margin-bottom:35px;  
}

.case__subtitle{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.004em;
    color: #22333B;
}

.more-cases__button{
    border: 1px solid #6F6F6F;
    border-radius: 6px;
    background:#fff;    
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #6F6F6F;
    padding:6px 12px;
    height:32px;
    margin-bottom:35px;
}
