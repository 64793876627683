.checkbox_field{
    border: 1px solid #6F6F6F;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 15px;
    position:relative;
}

.checkbox_label{
    position:relative;
    display:flex;
    align-items:center;
    margin-bottom:15px;

}

label{
    font-size: 14px;
    line-height: 22px;
    color: #6F6F6F;
}
.input_error{
    color:#EF4444;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.4px;
    position: relative;
    width: 360px;
    margin-top: -10px;
    margin-bottom: 10px;
    bottom: 0;
    left: 5%;
}

@media(max-width:500px){
    .checkbox_label{
        margin-bottom:0;
        flex-wrap:wrap;
    }
    .input_error{
        bottom:-20px;
        left:0;
    }
}
