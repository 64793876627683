.myProfile__container{
    display: flex;
    flex-direction: column;
    width: 68%;
    margin-bottom: 60px;
}
.myProfile__input-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;  
}

@media (max-width: 850px){
    .myProfile__container{
        width: 100%;
        align-items: center;
        margin-left: 0;
    }
    .myProfile__input-container{
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-right:20px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  input:checked + .slider {
    background-color: #ef4444;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #ef4444;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  .switch-privacy__container {
    margin-top: 46px;
    display:flex;
    align-items:center;
  }
  
  .switch-privacy__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #6f6f6f;
    margin-left:15px;
    margin-bottom:0;
  }
  
  .switch-privacy__link {
      font-weight: bold;
      color:#6f6f6f;
    }
.switch-privacy__link:hover {
    font-weight: bold;
    color:#ef4444;
}

.switch__container{
    margin-bottom:20px;
    display:flex;
    align-items:center;
}