.legislation_container{
    display: flex;
    justify-content: space-between;
    padding-bottom: 25px;
    border-bottom: 1px solid #EFEFEF;
    margin-top: 35px;
}

.legislation_title{
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.004em;
    flex-basis: 20%;
    margin-top:0;
}

.legislation_column{
    flex-basis: 85%;
    flex-wrap:wrap;
}

.legislation_column p{
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: justify;
    letter-spacing: 0.004em;
}

.legislation_content{
    font-size: 18px;
    line-height: 30px;
    text-align: justify;
    letter-spacing: 0.004em;
    color: var(--black);
    margin-bottom: 25px;
}

.legislation_links{
    display: flex;
    flex-direction: column;
}


@media(max-width: 768px){
    .legislation_container{
       flex-direction: column;
    }
    .legislation_content{
        margin-bottom: 0px;
        flex-basis:100%;
    }
}

