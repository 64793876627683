.detail__button{
    color:#22333B;
    border: 1px solid #22333B;;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    background: white;
    border-radius: 100px;
    padding:6px 20px;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
}

.detail__button:hover{
    color:#22333B;
}
