.member__text{
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #22333B;
    margin-bottom:60px;
}

.memberpage__flex{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap:30px;
}

.searchbar__wrapper {
    text-align: start;
}

.search-paragraph {
    color: #22333B;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.4px;
    text-align: start;
    margin-bottom: 6px;
    
}


.country__select {
    text-align: start;
    position: relative;
}

.custom-select {
    padding: 12px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    border: 1px solid #DADADA;
    display: flex;
    align-items: center;
    gap: 8px;
    width: max-content;
    color: #6F6F6F;
    line-height: normal;
    cursor: pointer;
}

.reset-button {
    color: #6F6F6F;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
    text-decoration-line: underline;
}

.custom-select:hover {
    border-color: #979797;
}

.custom-select.selected {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    border-color: #979797;
}

.custom-select.selected span {
    order: 0;
}

.custom-select.selected::after {
    content: '';
    width: 1px;
    height: 43px;
    display: block;
    background: #979797;
    order: 1;
}

.custom-select.selected img {
    order: 2;
    padding: 10px 10px 10px 0;
}

.country-dropdown {
    /* display: flex; */
    position: absolute;
    width: max-content;
    padding: 14px;
    flex-direction: column;
    /* align-items: flex-start; */
    gap: 12px;
    border-radius: 10px;
    border: 1px solid #DADADA;
    background: #FFF;
    z-index: 99;
    box-shadow: 0px 4px 40px 0px rgba(133, 133, 133, 0.10);
    height: 196px;
    overflow: auto;
    top: 55px;
}

.country-dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 6px 0;
    color: #6F6F6F;
}

.filters-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
}

ul li {
    display: flex;
    justify-content: space-between;
}

.search__mobile-button {
    display: none;
}

.no-expert-found {
    margin-bottom: 140px !important;
}

@media (max-width: 1200px){
    .memberpage__flex{
        grid-template-columns: repeat(3, 1fr);
    }
}
@media(max-width:1025px){
    .memberpage__flex{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media(max-width:600px){
    
    .member__text{
        margin-bottom:45px;
    }

    .filters-wrapper {
        margin-bottom: 0;
    }

    .search__mobile-button {
        text-align: start;
        display: flex;
        width: max-content;
        height: 36px;
        padding: 10px;
        gap: 6px;
        border-radius: 35px;
        background: #EF4444;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.4px;
        margin-bottom: 35px;
    }

    .custom-select {
        margin-bottom: 20px;
    }
}

@media(max-width: 400px){
    .memberpage__flex{
        display: flex;
        flex-wrap:wrap;
        justify-content: center;
    }
}