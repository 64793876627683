.membership__title{
    font-weight: 800;
    font-size: 58px;
    line-height: 70px;
    letter-spacing: 0.4px;
    color: #22333B;
    margin-top:0;
    margin-bottom:25px;
}

@media(max-width:1025px){
    .membership__title{
        font-size:50px;
    }
}



@media(max-width:600px){
    .membership__title{
        font-size: 32px;
        line-height: 42px;
        text-align:center!important;
    }
}