/* Select style */

.input_select-container{
  margin: 0 !important;
  display: flex;
  flex-direction: column;
}
.input_select{
    width: 360px;
    height: 54px;
    border: 1px solid #979797 !important;
    border-radius: 49px;
    padding: 0px 20px;
    appearance: none;
    background: url('../../assets/black-triangle.svg') no-repeat scroll 90%;
  }

.input_select::placeholder{
  color:#979797!important;
}  
  
.input_select:focus{
    border: 1px solid #979797 !important;
    color: #979797 !important;
    border-color: #979797 !important;
}
.input_select-label{
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.4px;
  color: #22333B;
  margin-bottom: 10px;
}

.input_error{
  color:#EF4444;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.4px;
  margin-top:7px;
  margin-left:2%;
}

@media (max-width: 850px){
  .input_select-container{
    margin-bottom: 35px !important;
  }
}

@media (max-width: 360px){
  .input_select{
    width: 280px;
  }
}




