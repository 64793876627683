.red__button{
    color:#22333B;
    border: 1px solid #22333B;;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    background: white;
    border-radius: 100px;
    padding:14px 30px;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
}

.red__button:hover{
    color:#22333B;
}

.margin-right{
    margin-right:10px;
}

@media(max-width:375px){
    .margin-right{
        margin-right:0;
        margin-bottom:10px;
    }
}
