.thankyou-grey-section{
    background: #FAFAFA;
    border-radius: 20px;
    padding:35px 70px;
    margin-bottom:60px;
    margin-top:-200px;
}

.grey-title{
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #6F6F6F;
    margin-bottom:25px;
}

.thankyou-flex{
    display:flex;
    justify-content:space-between;
    flex-wrap:wrap;
}

.flex-centered{
    display:flex;
    align-items:center;
    justify-content:center;
}

.event-date{
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.004em;
    color: #22333B;
}

@media(max-width:500px){
    .thankyou-grey-section{
        padding:30px 15px;
    }

    .grey-title{
        margin-bottom:25px;
    }

    .thankyou-flex{
        flex-direction:column-reverse;
    }

    .event-date{
      margin-bottom:25px;  
    }
}