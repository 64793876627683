.flow_container{
    width: 60%;
}
.signup__form{
    width: 100%;
}
.form__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 45px;
}

.signup_step-section{
    position: absolute;
    top: 0;
    right: 0;
    background-color:  #FAFAFA;
    width: 34%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top:4%;
}

.buttons_container{
    display: flex;
    width: 65%;
    margin-bottom: 45px;
    margin-top: 45px;
}

@media(max-width: 1024px){
    .signup_step-section{
        display: none;
    }
    .signup__form{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .form__container{
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 500px){
    .buttons_container{
        width:100%;
        align-items: center;
        height: 130px;
    }
}