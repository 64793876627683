.access-member__block{
    background: #FAFAFA;
    border-radius: 20px;
    padding-top:45px;
    padding-bottom:45px;
    margin-top:60px;
}

@media(max-width:500px){
    .access-member__block{
        padding-top:25px;
        padding-bottom:25px;
        padding-left:15px;
        padding-right:15px;
        margin-top:37px;
    }
}

.access-member__title{
    font-weight: bold;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    margin-top:0;
    margin-bottom:20px;
}

@media(max-width:500px){
    .access-member__title{
        font-size: 28px;
        line-height: 38px;
        margin-bottom:15px;
    }
}

@media(max-width:390px){
    .access-member__title{
        font-size: 27px;
    }
}

@media(max-width:320px){
    .access-member__title{
        font-size: 21px;
    }
}

.access-member__text{
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    margin-top:0;
    margin-bottom:30px;
}

@media(max-width:320px){
    .access-member__text{
        font-size: 15px;
    }
}


.white__button{
    border: 1px solid #fff;
    color:var(--black-idi);
    background:#fff;
    box-sizing: border-box;
    border-radius: 100px;
    padding-top:14px;
    padding-bottom:14px;
    display:inline-block;
    width:fit-content;
    padding-right:40px;
    padding-left:40px;
    font-weight:bold;
    font-size: 16px;
    line-height: 22px;
    font-weight:800;
    text-decoration:none;
    margin-right:30px;
}

@media(max-width:500px){
    .white__button{
        padding-right:30px;
        padding-left:30px;    
    }
}

@media(max-width:390px){
    .white__button{
        padding-right:20px;
        padding-left:20px;    
    }
}

@media(max-width:320px){
    .white__button{
        padding-right:10px;
        padding-left:10px; 
        font-size:14px;  
    }
}

.white__button:hover{
    background:#EF4444;
    color:#fff;
    text-decoration:none;
}

/*Pulsante rosso*/

.red__button{
    background:#EF4444;
    border-radius: 100px;
    color:#fff;
    padding-top:14px;
    padding-bottom:14px;
    display:inline-block;
    width:fit-content;
    padding-right:40px;
    padding-left:40px;
    font-size: 16px;
    line-height: 22px;
    font-weight:800;
    text-decoration:none;
}

@media(max-width:500px){
    .red__button{
        padding-right:30px;
        padding-left:30px;    
    }
}

@media(max-width:390px){
    .red__button{
        padding-right:20px;
        padding-left:20px;    
    }
}

@media(max-width:320px){
    .red__button{
        padding-right:10px;
        padding-left:10px;  
        font-size:14px;  
    }
}


.red__button:hover{
    background: #DF4949;
    color:#fff;
    text-decoration:none;
}

.flex-center{
    display:flex;
    justify-content:center;
}

