.user-conference{
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0.4px;
    color: #22333B;
    margin-bottom:35px;
    margin-top:60px;
}

.conference-flex{
    display:flex;
    justify-content:space-between;
    flex-wrap:wrap;
    width:61%;
}

.flex-large{
    flex-basis:75%;
    display:flex;
    flex-direction:column;
}

.flex-small{
    flex-basis:25%;
    display:flex;
    flex-direction:column;
    text-align:right;
}

.grey-title{
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #6F6F6F;
    margin-bottom:25px;
}

.conference-black-text{
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.004em;
    color: #22333B;
}

.grey-text{
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.4px;
    color: #22333B;
    margin-top:15px;
}

.black-option{
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.004em;
    color: #22333B;
    margin-bottom:15px;
}

.radio-container {
    display: flex;
    align-items:center;
    position: relative;
    margin-bottom: 15px;
    padding-left:25px;
    cursor: pointer;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.004em;
    color: #22333B;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

.radio-container-np{
    padding-left:0!important;
}  
  
/* Hide the browser's default radio button */
.radio-container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.optionals-checkbox {
    accent-color: #EF4444;
    margin-bottom: 40px;
    cursor: pointer;

    @media (max-width: 500px) {
        margin-bottom: 0;
    }
}

.label-wrapper {
    display: flex;
    gap: 15px;
    align-items: center;
    cursor: pointer;
}

.intolerances-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: -25px;
    margin-bottom: 25px;
}

.intolerances-input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    
    label {
        color: #22333B;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0.4px;
    }

    input {
        border-radius: 49px;
        border: 1px solid #979797;
        padding: 12px 20px;
        color: #6F6F6F;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0.4px;
    }
}

.checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border: 1px solid #6F6F6F;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .radio-container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .radio-container input:checked ~ .checkmark {
    background-color: #EF4444;
    border:none;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .radio-container input:checked ~ .checkmark:after {
    display: block;
  }
  
/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
       top: 5px;
      left: 5px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: white;
}

.conference-option-section{
    padding-bottom:10px;
    border-bottom: 1px solid #DADADA;
    padding-top:25px;
    width:61%;
}

.conference-box{
    width:61%;
}

.conference-option-counter{
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.4px;
    color: #22333B;
    display:flex;
    align-items:center;
    margin-bottom:15px;
    padding-bottom:25px;
}

.counter-minus{
    margin-right:15px;
    height:25px;
    width:25px;
    object-fit: cover;
    cursor:pointer;

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

.counter-plus{
    margin-left:15px;
    height:25px;
    width:25px;
    object-fit: cover;
    cursor:pointer;

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

.strong-option{
    font-weight:700;
    padding-bottom:25px;
}

.counter-flex{
    width:100%;
}

@media(max-width:1024px){
    .conference-flex{
        width:100%;
    }
    .conference-option-section{
        width:100%;
    }

    .conference-box{
        width:100%;
    }
}


@media(max-width:500px){
    .user-conference{
        font-size: 26px;
        line-height: 36px;
        margin-bottom:25px;
    }

    .flex-large{
        flex-basis:100%;
    }

    .flex-small{
        flex-basis:100%;
        text-align:left;
    }

    .grey-text{
        margin-bottom:45px;
    }

    .strong-option{
        padding-bottom:0;
        margin-bottom:0;
    }

    .counter-flex{
        margin-bottom:25px;
    }

    .event-title{
        margin-top:45px;
        margin-bottom:0;
    }
}

.red__error{
    color:#EF4444;
}