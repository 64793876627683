/* Select style */
.select_container{
    width: 213px;
    padding: 15px 30px;
    border: 1px solid #EF4444;
    box-sizing: border-box;
    border-radius: 100px;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #EF4444;
    outline-color: #EF4444;
    appearance: none;
    background: url('../../assets/Triangle.svg') no-repeat scroll 90%;
  }
  
.select_container:focus{
    border: 1px solid red !important;
    color: red !important;
    border-color: red !important;
    
  }
  
  option{
    padding-top: 10px;
  }

