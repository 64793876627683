.myProfile__container{
    display: flex;
    flex-direction: column;
    width: 68%;
    margin-bottom: 60px;
}
.myProfile__input-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;  
}

@media (max-width: 850px){
    .myProfile__container{
        width: 100%;
        align-items: center;
        margin-left: 0;
    }
    .myProfile__input-container{
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
}