.main__container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    margin-top:60px;
  } 

  @media (min-width: 576px) {
    .main__container {
        width: 540px;
    }
  }
  @media (min-width: 768px) {
    .main__container {
        width: 720px;
    }
  }
  @media (min-width: 992px) {
    .main__container {
        width: 960px;
    }
  }
  @media (min-width: 1200px) {
    .main__container {
        width: 1140px;
    }
  }

.accordion__container{
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgb(133 133 133 / 10%);
    border-radius: 20px 20px 0px 0px;
    margin-top: 45px;
    margin-bottom:35px;
}  



.accordion__title{
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0.4px;
    color: #22333B;
}
  