/* Select style */
.select_container-documents{
    position: relative;
}

.select_container-documents::before{
    content: url("../../assets/select-world.svg");
    width: 14px;
    height: 14px;
    object-fit: cover;
    position: absolute;
    left: 32px;
    top: 3.5px;
}

.select_document{
    width: 111px;
    padding: 6px;
    padding-left: 25px;
    border: 1px solid #EF4444;
    box-sizing: border-box;
    border-radius: 100px;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    color: #EF4444;
    outline-color: #EF4444;
    appearance: none;
    background: url('../../assets/Triangle.svg') no-repeat scroll 90%;
    margin-left: 25px;
}
  
.select_document:focus{
    border: 1px solid red !important;
    color: red !important;
    border-color: red !important; 
  }
  option{
    padding-top: 10px;
  }

  @media(max-width: 768px){
    .select_document{
        margin-left: 0;
    }
    .select_container-documents::before{
        left: 8px;
    }
  }