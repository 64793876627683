.input_container{
    display: flex;
    flex-direction: column;
    position: relative;
}
.input_label{
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.4px;
    color: #22333B;
    margin-bottom: 10px;
}
.input_field{
    width: 360px;
    height: 54px;
    border: 1px solid #979797 !important;
    border-radius: 49px;
    margin-bottom: 30px;
    padding: 0px 20px;
}
.input_field::placeholder{
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.4px;
    color: #6F6F6F;
}

.input_error{
    color:#EF4444;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.4px;
    position: relative;
    width: 360px;
    margin-top: -10px;
    margin-bottom: 10px;
    bottom: 0;
    left: 5%;
}

@media (max-width: 360px) {
    .input_field{
        width: 280px;
    }
}

.subscribeWidth{
    width:auto !important;
}

.caseHeight{
    height:174px!important;
    border-radius:27px!important;
}