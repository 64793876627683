.searchbar__group{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
}
.searchbar_container{
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
}
.input_search{
    width: 100%;
    background: #FAFAFA;
    border: 1px solid #DADADA;
    border-radius: 46px;
    filter: drop-shadow(-2px 0px 20px rgba(41, 41, 41, 0.05));
    padding: 15px 25px 15px 51px;
}

.input_search_img{
    position: absolute;
    right: 10px;
    width: 36px;
    height: 36px;
    object-fit: cover;
    cursor: pointer;
}

.input_clear_img{
    position: absolute;
    right: 56px;
    object-fit: cover;
    cursor: pointer;
}

.search_icon {
    position: absolute;
    left: 19px;
    z-index: 99;
}

@media(max-width:600px){
    .input_search_img{
        display: none;
    }

}

