/*NOT LOGGED IN*/

.not-logged-in__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  width:100%;
}

.not-logged-in__container h3 {
  color: #22333b;
  font-weight: 700;
  font-size: 35px;
}

.not-logged__flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
}

@media (max-width: 375px) {
  .not-logged-in__container h3 {
    font-size: 30px;
  }
}

