.documents__container {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(133, 133, 133, 0.1);
  border-radius: 20px 20px 0px 0px;
  padding: 45px;
  margin-top: 15px;
  margin-bottom: 60px;
}
.documents__container-title {
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: 0.4px;
  color: var(--black);
}

.search-parameter {
  margin-bottom: 15px;
}

.search-parameter span {
  color: var(--red);
  padding: 5px 10px;
  border: 1px solid red;
  border-radius: 20px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .documents__container {
    padding: 25px 15px;
    margin-top: 15px;
    margin-bottom: 45px;
  }
  .documents__container-title {
    font-size: 26px;
    line-height: 36px;
  }
}

.main__container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.membership__section {
  margin-top: 60px;
  min-height: 300px;
}

@media (max-width: 500px) {
  .membership__section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 576px) {
  .main__container {
    width: 540px;
  }
  .membership__section {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  .main__container {
    width: 720px;
  }
}
@media (min-width: 992px) {
  .main__container {
    width: 960px;
  }
}
@media (min-width: 1200px) {
  .main__container {
    width: 1140px;
  }
}

/* Document area */

.document__area {
  width: 100%;
  padding: 45px;
  padding-bottom:0;
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(133, 133, 133, 0.1);
  border-radius: 20px;
  margin-bottom:60px;
}

.document__area-title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: 0.4px;
  color: #22333b;
  margin-bottom: 35px;
}

.document__row {
  display: flex;
  flex-wrap:wrap;
  justify-content: space-between;
  padding-bottom:25px;
}

.document__row-border {
  border-bottom: 1px solid #EFEFEF;
}

.title__column {
  flex-basis: 17.54%;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.004em;  
  color: #22333B;
}

.row__margin{
  margin-top:25px;
}

.documents__column {
  flex-basis: 76.31%;
  
}

.author__title{
  color:#22333B;
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.072px;
  margin-bottom:30px;
}

.author__text{
  color:#22333B;
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.072px;
  margin-bottom:30px;
}

.reports_container-box{
  display: flex;
  align-items: center;
  margin-bottom:25px;
}

.reports_document-download{
  display:flex;
  align-items:center;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.004em;
  color: #22333B;
  margin: 0;
  text-decoration: none;
  transition:all 0.3s;
}

.reports_document-download:hover{
  color:#EF4444;
}

.reports_document-download::before{
  content: url(../../assets/download-svg.svg);
  width: 18px;
  height: 18px;
  object-fit: cover;
  margin-right: 15px;
}

.reports_document-download:hover .reports_document-download::before{
  content: url(../../assets/download-svg.svg);
  width: 18px;
  height: 18px;
  object-fit: cover;
  margin-right: 10px;
}

@media (max-width: 600px) {
  .document__area {
    padding: 25px 15px;
    margin-bottom: 70px;
  }

  .document__area-title {
    font-size: 26px;
    line-height: 36px;
  }
  .document__row{
    padding-bottom:25px;
  }
  .title__column {
    flex-basis: 100%;
    margin-bottom: 25px;
  }
  .documents__column {
    flex-basis: 100%;
  }
}

.link__container{
  display: flex;
  color: var(--black);
  text-decoration: none;
  align-items: baseline;
  transition: all .3s;
}

.link__container:hover .link__svg{
  filter: invert(73%) sepia(95%) saturate(6060%) hue-rotate(335deg) brightness(97%) contrast(94%);
}

.link__container:hover .link__content{
  color: #ef4444;
}
.link__container:hover{
  color: #ef4444;
}

.link__svg{
  margin-right: 15px;
  width: 18px;
  height: 18px;
  object-fit: cover;
}
