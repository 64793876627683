.tooltip_icon{
    color:#EF4444;
}

.tooltip{
    margin-left:16px;
    position:relative;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: fit-content;
    border:1px solid #22333B;
    border-radius:10px;
    background-color: #fff;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.004em;
    color: #22333B;
    text-align: center;
    padding: 8px 12px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: unset;
    right:-44px;
    margin-left: -98px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  @media(max-width:500px){
    .tooltip .tooltiptext{
        font-size:18px;
        right:-56px;
    }
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }