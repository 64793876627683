.myProfile__container{
    display: flex;
    flex-direction: column;
    width: 68%;
    margin-bottom: 60px;
}
.myProfile__input-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;  
}

@media (max-width: 850px){
    .myProfile__container{
        width: 100%;
        align-items: center;
        margin-left: 0;
    }
    .myProfile__input-container{
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
}

.footer_link-href{
    display: flex;
    align-items: baseline;
    text-decoration: none;
    color: var(--red);
    margin-top: 60px;
    
}

.footer_link-href h5{
    font-size: 18px;
}

.footer_link-href:hover{
    color: var(--red);
}
.footer_link-href:hover .footer_link-svg{
    margin-left: 18px;
    transition: .3s;
}

.footer_link-href .footer_link-svg{
    margin-left: 10px;
    transition: .3s;
}