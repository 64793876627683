.reports_container{
    width: 100%;
    padding: 35px 45px;
    background-color: white;
    box-shadow: 0px 0px 50px rgba(133, 133, 133, 0.1);
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
}

.reports_country-name{
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.004em;
    color: #22333B;
    flex-basis: 25%;
    margin: 0;
}

.reports_link-container{
    display: flex;
    flex-basis: 75%;
    justify-content: space-between;
}
.reports_container-box{
    display: flex;
    align-items: center;
}

.reports_document-download{
    content: url(../../assets/download-svg.svg);
    width: 18px;
    height: 18px;
    object-fit: cover;
    margin-right: 15px;
}

.reports_expert-link{
    background: url('../../assets/expert.svg');
    width: 18px;
    height: 18px;
    object-fit: cover;
    margin-right: 15px;
    position: relative;
    display: inline-block;
}
.reports_agency{
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.004em;
    color: #22333B;
    margin: 0;
}
.tooltip {
    display:inline-block;
    position:relative;
}

.tooltip .top {
    width: 63px;
    top:-20px;
    left:50%;
    transform:translate(-50%, -100%);
    padding:8px 12px;
    color:black;
    background-color:white;
    font-weight:normal;
    font-size:13px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    border: 1px solid black;
    transition: .2s ease-in-out;
    visibility: hidden;
    opacity: 0;
}

.tooltip:hover .top {
    visibility: visible;
    opacity: 1;
    transition: .2s ease-in-out;
}

@media(max-width: 768px){
    .reports_container{
        flex-direction: column;
        padding: 25px 15px;
    }
    .reports_link-container{
        flex-direction: column;
    }
    .reports_container-box{
        margin-top: 25px;
    }
}