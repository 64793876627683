.avatar_img{
    object-fit: cover;
    border-radius: 50%;
}



.extra-large{
    width:165px;
    height:165px;
    margin-right:40px;
}
@media(max-width:500px){
    .extra-large{
        margin-right:0px;
    }
}
.large{
    width: 97px;
    height: 97px;
}

.medium{
    width:60px;
    height:60px;
}

.small{
    width: 36px;
    height: 36px;
}

.very-small{
    height: 26px;
    width:26px;
}

.avatar_container{
    position:relative;
}

.avatar_crown{
    position:absolute;
    bottom:2%;
    right:2%;
    height:20px;
    width:20px;
}

@media(max-width:500px){
    .extra-large{
        height:100px;
        width:100px;
    }
}
