.memberinfo__flex{
    display:flex;
    align-items:center;
    margin-bottom:25px;
}

.membercard__img{
    height:60px;
    width:60px;
    border-radius:50%;
    object-fit:cover;
    margin-right:20px;
}

.membercard__info{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    margin-left:20px;
}

.membercard__name{
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.004em;
    color: #22333B;
    margin-bottom:5px;
    margin-top:0;
}

.membercard__data{
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.004em;
    color: #22333B;
    margin-bottom:5px;
    text-transform: uppercase;
    margin-top:0;
    text-align: left;
    max-width: 220px;
}

.membercard__strong{
    font-weight:700;
}