.member__text{
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #22333B;
    margin-bottom:60px;
}

.memberpage__search{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-bottom: 60px;
}

.memberpage__search__text{
    color: #6F6F6F;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 187.5% */
    letter-spacing: 0.4px;
    margin-bottom: 6px;
}

.memberpage__search__divider{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
}

.mobile_search_img{
    height: 36px;
    object-fit: cover;
    cursor: pointer;

}

.search_reset_link{
    cursor: pointer;
    color: #6F6F6F;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
    text-decoration-line: underline;    
}

.memberpage__flex{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
}


@media(max-width:1025px){
    .memberpage__flex{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media(max-width:600px){
    .memberpage__search__divider{
        flex-direction: column;
        align-items: start;
        gap: 20px;
    }
    .memberpage__search__results{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
    }
}

@media(max-width:500px){
    .member__text{
        margin-bottom:45px;
    }

    .memberpage__flex{
        grid-template-columns: repeat(1, 1fr);
    }
}

@media(min-width:600px){
    .memberpage__search__divider{
        width: 100%;
    }
    .mobile_search_img{
        display: none;
    }
}