.lastUpdate__box{
    background: rgba(239, 68, 68, 0.03);
    border-radius: 51px;
    padding:4px 15px;
    width:fit-content;
    margin:0 auto;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.004em;
    color: #EF4444;
    margin-bottom:49px;
}

.strong{
    font-weight:bold;
}