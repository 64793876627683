.step__container{
    display: flex;
    align-items: center;
    width: auto;
    margin-left: 80px;
    margin-bottom: 35px;
}
.step_svg{
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin-right: 20px;
}
.step_number{
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    color: #22333B;
    background: #EFEFEF;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 20px;
    text-align: center;
    margin-bottom: 0;
    padding: 2px;
}
.step_description{
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.4px;
    color: rgba(34, 51, 59, 0.4);
}