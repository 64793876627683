.red__button{
    background: #EF4444;
    border-radius: 100px;
    color: #fff;
    padding-top: 14px;
    padding-bottom: 14px;
    display: inline-block;
    width: fit-content;
    font-size: 16px;
    line-height: 22px;
    font-weight: 800;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: .5s;
}

.red__button:hover{
    color: white;
    background-color: #df4949;
    transition: .5s;
}