.search-result__box{
    box-shadow: 0px 0px 50px rgba(133, 133, 133, 0.1);
    border-radius: 20px;
    padding:45px;
    margin-bottom:35px;
    position:relative;
    text-decoration:none!important;
    color:#22333B!important;
}

.result__title{
    font-weight: bold;
    font-size: 26px;
    line-height: 36px;
    letter-spacing: 0.4px;
    color:#22333B!important;
    margin-bottom:0;
}

.result__link{
    color:#22333B;
    text-decoration:none;
}

.search-result__box:hover{
    text-decoration:none!important;
}

.arrow_icon{
    position:absolute;
    top:45%;
    right:45px;
    color:#EF4444;
    font-size:17px;
    
}

.result__category{
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;
    letter-spacing: .004em;
    color: #22333b;
}

.no_result{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20%;
}
.no_result-title{
    font-weight: 800;
    font-size: 58px;
    line-height: 70px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #22333B;
}
.no_result-description{
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.4px;
}

.result__others{
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;
    letter-spacing: 0.4px;
    color: #22333B;
}

@media(max-width:1025px){
    .arrow_icon{
        right:35px;
    }
}
@media(max-width:850px){
    .arrow_icon{
        right:25px;
    }
   
}




@media(max-width:500px){
    .search-result__box{
        padding:15px 25px;

    }

    .result__title{
        width:90%;
    }

    .arrow_icon{
        top:20%;
        right:15px;
    }
    .no_result-title{
        font-size: 32px;
        line-height: 42px;
    }
}

.homepage-search-results{
    margin-top:60px!important;
}