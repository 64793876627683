.membership__container{
    flex-basis:75%;
    margin-bottom:60px;
}



.membership__flex{
    display:flex;
    flex-wrap:wrap;
    margin-bottom:60px;
}

.cancel-subscription__text{
    margin-top:45px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.4px;
    color: #929292;

}

.cancel-subscription__link{
    color:#EF4444;
    text-decoration:none;
    transition:.3s;
    font-weight:700;

}

.cancel-subscription__button{
    background: #FFFFFF;
    border: 1px solid #22333B;
    border-radius:100px;
    width:fit-content;
    padding:14px 68px;
    margin-right:30px;
}

.cancel-subscription__button-container{
    display:flex;
}

.cancel-subscription__link:hover{
    text-decoration:underline;
    transition:.3s;
    color:#EF4444;
}

@media(max-width:500px){
    .membership__container{
        flex-basis:100%;
        margin-bottom:45px;
    }

    .membership__flex{
        margin-bottom:25px;
    }

    .cancel-subscription__button{
        padding:14px 30px;
    }
}

