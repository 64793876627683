.info__container{
    display:flex;
    flex-direction:column;
    flex-basis:50%;
}

@media(max-width:500px){
    .info__container{
        flex-basis:100%;
        margin-bottom:35px;
    }
}

.info__span{
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #6F6F6F;
    margin-bottom:15px;
}

.info__text{
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.004em;
    color: #22333B;
}