.password__container{
    display:flex;
    align-items:center;
    flex-direction:column;
    margin-top:100px;
    margin-bottom:360px;
}

.password__logo{
    width:15%;
    margin:0 auto;
    margin-bottom:98px;
}

.changed-password__title{
    font-style: normal;
    font-weight: 800;
    font-size: 58px;
    line-height: 70px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #22333B;
    margin-bottom:25px;
}

.changed-password__text{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #22333B;
    margin-bottom:60px;
}






@media(max-width:500px){
    .password__container{
        margin-top:60px;
        margin-bottom:100px;
    }

    .password__logo{
        width:25%;
    }

    .changed-password__title{
        font-size:38px;
        line-height:42px;
    }
}