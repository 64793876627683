.documents__container{
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(133, 133, 133, 0.1);
    border-radius: 20px 20px 0px 0px;
    padding: 45px;
    margin-top: 15px;
    margin-bottom:60px;
}
.documents__container-title{
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0.4px;
    color: var(--black);
}

.search-parameter{
    margin-bottom: 15px;
}

.search-parameter span{
    color: var(--red);
    padding: 5px 10px;
    border: 1px solid red;
    border-radius: 20px;
    cursor: pointer;
}

.no-results {
    margin-bottom: 20px;
}


@media(max-width: 600px){
    .documents__container{
        padding: 25px 15px;
        margin-top: 15px;
        margin-bottom:45px;
    }
    .documents__container-title{
        font-size: 26px;
        line-height: 36px;
    }
}