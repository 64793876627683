.signup__container{
    display: flex;
    position: relative;
}
.signup__form{
    width: 65%;
}
.form__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 45px;
}

.signup_step-section{
    position: absolute;
    top: 0;
    right: 0;
    background-color: var( --light-grey);
    width: 34%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top:4%;
}

.buttons_container{
    display: flex;
    width: 64%;
    margin-bottom: 45px;
}

.delete-user__link{
    color:#EF4444;
    font-weight:700;
    cursor:pointer;
}

.add-user__text{
    margin-top:25px;
}

.user__table-flex{
    display:flex;
    justify-content: space-between;
    position: relative;
}

.user__table{
    flex-basis:30%;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.004em;
    color: #22333B;
}

.input__table{
    position:absolute;
    top:32%;
}


@media(max-width: 1024px){
    .signup_step-section{
        display: none;
    }
    .signup__form{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .form__container{
        flex-direction: column;
        align-items: center;
    }
}

.scrollable{
    margin-top:20px;
}

@media (max-width: 500px){
    .buttons_container{
        width:100%;
        align-items: center;
        height: 130px;
    }
    .scrollable{
        overflow: auto;
        white-space: nowrap;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .scrollable::-webkit-scrollbar {
    display: none;
    }
  
/* Hide scrollbar for IE, Edge and Firefox */
    .scrollable {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    }


    .user__table-flex{
        width: 750px;
    }
    .user__table{
        text-align: center;
    }
}
