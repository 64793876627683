.addeduser__container{
    display:flex;
    justify-content: space-between;
    margin-bottom:7px;
    margin-top:25px;
    width:100%;
    margin-bottom:25px;
    align-items: center;
    border-bottom: 1px solid #EFEFEF;
    padding-bottom: 25px;
}

.user_name{
    display:flex;
    flex-basis: 30%;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.004em;
}

.break{
    word-break:break-all;
}

.user_delete{
    position: absolute;
    right: 0;
    cursor: pointer;
}

@media (max-width: 500px){
    .user_name{
        justify-content: center;
    }
    
}