.search-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #DADADA;
    margin-bottom: 35px;
    padding-bottom: 16px;
    flex-wrap: wrap;
}

.search-wrapper .search-actions {
    display: flex;
    align-items: center;
    gap: 32px;
}

.reset-button {
    color: #6F6F6F;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
    text-decoration-line: underline;
    cursor: pointer;
}

.search__mobile-button {
    display: none !important;
}

.no-border {
    border-bottom: none;
}

.search-wrapper .filters-wrapper {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
}

h4 {
    color: #6F6F6F;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 16px;
    /* margin-top: 30px; */
}

.input_search_img{
    width: 36px;
    height: 36px;
    object-fit: cover;
    cursor: pointer;
}

.input-autocomplete__wrapper {
    position: relative;
}

@media(max-width:600px){

    .search-wrapper {
        border-bottom: none;
        row-gap: 16px;
        gap: 16px;
        justify-content: start;
    }

    .search__mobile-button {
        text-align: start;
        display: flex !important;
        width: max-content;
        height: 36px;
        padding: 10px;
        gap: 6px;
        border-radius: 35px;
        background: #EF4444;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.4px;
        order: 1;
    }

    .search-actions img {
        display: none !important;
    }

    .search-actions {
        order: 2;
    }

    .filters-wrapper {
        margin-bottom: 16px;
    }
}