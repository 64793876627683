.conference-details-flex{
    display:flex;
    margin-bottom:45px;
}


.conference-image{
    width:165px;
    height:100px;
    object-fit: cover;
    border-radius:20px;
    margin-right:25px;
}

.conference-details-text-box{
    display:flex;
    flex-direction:column;
}

.conference-details-name{
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.004em;
    color: #22333B;
    margin-bottom:10px;
}

.conference-details-description{
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.004em;
    color: #22333B;
}

.conference-red-link{
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #EF4444;
    cursor: pointer;
}

.conference-red-link::after{
    content: url('../../assets/red-arrow.svg');
    margin-left: 8px;
}

.arrow-right{
    color:#EF4444;
    margin-left:8px;
}

@media(max-width:500px){
    .conference-image{
        width:100%;
        height:160px;
        margin-bottom:25px;
        margin-right:0;
    }

    .conference-details-flex{
        flex-wrap:wrap;
    }

    .conference-details-description{
        margin-bottom:0;
    }

    .conference-red-link{
        margin-top:15px;
    }
}