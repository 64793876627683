.pageDescription{
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: var(--black);
    letter-spacing: 0.4px;
}

@media(max-width: 500px){
    .pageDescription{
        text-align: center;
    }
}