.dropdown-container {
    text-align: start;
    position: relative;
}

.custom-select {
    height: 36px !important;
    padding: 12px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    border: 1px solid #DADADA;
    display: flex;
    align-items: center;
    gap: 8px;
    width: max-content;
    /* margin-bottom: 35px; */
    color: #6F6F6F;
    line-height: normal;
    cursor: pointer;
}

.custom-select:hover {
    border-color: #979797 !important;
}

.custom-select.selected {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    border-color: #979797;
}

.custom-select.selected span {
    order: 0;
}

.custom-select.selected::after {
    content: '';
    width: 1px;
    height: 100%;
    display: block;
    background: #979797;
    order: 1;
}

.custom-select.selected img {
    order: 2;
    padding: 10px 10px 10px 0;
}

.dropdown {
    /* display: flex; */
    position: absolute;
    width: max-content;
    padding: 14px;
    flex-direction: column;
    /* align-items: flex-start; */
    gap: 12px;
    border-radius: 10px;
    border: 1px solid #DADADA;
    background: #FFF;
    z-index: 99;
    box-shadow: 0px 4px 40px 0px rgba(133, 133, 133, 0.10);
    max-height: 196px;
    overflow: auto;
    top: 55px;
}

.dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 6px 0;
    color: #6F6F6F;
}

.dropdown ul li {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    cursor: pointer;
}