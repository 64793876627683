.link__wrapper{
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    width:95%;
}
.link__container{
    display: flex;
    color: var(--black);
    text-decoration: none;
    align-items: baseline;
}

.link__container:hover .link__svg{
    filter: invert(73%) sepia(95%) saturate(6060%) hue-rotate(335deg) brightness(97%) contrast(94%);
}

.link__container:hover .link__content{
    color: #ef4444;
}
.link__container:hover{
    color: #ef4444;
}

.link__svg{
    margin-right: 15px;
    width: 18px;
    height: 18px;
    object-fit: cover;
}

.link__content{
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.004em;
    color: var(--black);
    margin-top: 0;
    margin-bottom:0;
    text-align: left;
}

@media(max-width: 768px){
    .link__wrapper{
        flex-direction: column;
    }
    .link__container{
        margin-bottom: 0px;
        margin-top: 25px;
    }
}