.member__card{
    box-shadow: 0px 4px 50px rgba(133, 133, 133, 0.1);
    border-radius: 20px;
    padding:35px 25px;
    flex-basis:32%;
    margin-bottom:30px;
    height:auto;
    position: relative;
}

@media(max-width:500px){
    .member__card{
        margin-bottom:25px;
    }
}