.autocomplete {
    display: flex;
    width: auto;
    padding: 14px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-radius: 10px;
    border: 1px solid #DADADA;
    background: #FFF;
    box-shadow: 0px 4px 40px 0px rgba(133, 133, 133, 0.10);
    position: absolute;
    top: 50px;
    z-index: 1;
    max-height: 150px;
    overflow: auto;
}

.autocomplete.large {
    top: 60px;
    max-width: 100%;
    width: 100%;
}

.autocomplete ul {
    margin: 0;
    list-style: none;
    padding: 0;
}

.autocomplete ul li {
    padding: 6px 0px;
    align-items: flex-start;
    gap: 10px;
    color: #6F6F6F;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
}