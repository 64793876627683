.login__container{
    width: 100%;
    height: 100%;
    background-color:rgba(0, 0, 0, 0.6);
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
}
.login__card-container{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 570px;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 0px 30px rgba(0, 43, 85, 0.05);
    border-radius: 20px;
    padding: 25px 45px 60px;
}

.login__card-header{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom:  0.5px solid #6F6F6F;
}
.login__card-header-title{
    font-weight: 800;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.4px;
    color: var(--red);
}
.login__card-header-close{
    width: 14px;
    height: 14px;
    object-fit: contain;
    cursor: pointer;
}

.login__card-title{
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0.4px;
    color: var(--black);
    margin-top: 50px;
}
.login__card-description{
    margin-top: 15px;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.004em;
    color: #000000;
    margin-bottom: 45px;
}


@media (max-width: 500px){
    .login__card-container{
        width: 100%;
        height:auto;
    }
}