.signup__container{
    display: flex;
    position: relative;
}

.cannotRenew__container{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    text-align: center;
    width:100%;
    margin-top:120px;
    margin-bottom:120px;
}

.cannotRenew__container p{
   font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    margin: 0 auto;
    margin-bottom: 40px 
}
