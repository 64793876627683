.banner_container{
    display: flex;
    justify-content: space-between;
    padding: 60px 45px;
    background: #FAFAFA;
    border-radius: 20px;
    margin-bottom: 45px;
    width: 100%;
    margin-top:60px;
}

.banner_title{
    font-weight: bold;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 0.4px;
    color: var(--black);
    margin: 0;
}

@media(max-width: 768px){
    .banner_container{
        flex-direction: column;
        align-items: center;
        padding: 35px 40px;
        margin-top:45px;
    }
    .banner_title{
        font-size: 32px;
        line-height: 44px;
        text-align: center;
        margin-bottom: 45px;
    }
}