.membercard-icons__flex{
    display:flex;
    justify-content: space-between;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    padding: 0px 25px;
}

.icon__circle{
    height:30px;
    width:30px;
    border-radius:50%;
    background: rgba(239, 68, 68, 0.04);
    display:flex;
    align-items:center;
    justify-content:center;
    color:#EF4444;
    font-size:12px;
}
.membercard_icon{
    width: 30px;
    height: 30px;
    display: flex;
    margin-right: 20px;
}

.member__card-other-info{
    display: flex;
    justify-content: space-between;
    width: 30%;
}
.telephone__container{
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}
.membercard__phone{
    display: flex;
    align-items: center;
    width: 50%;
}
.telephone__numb{
    color: black;
    text-decoration: none !important;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 12px;
    white-space: nowrap;
   
}
