
.coverbackground{
    background-size:cover!important;
    background-position:center!important;
    background-repeat: no-repeat!important;
}

.main__container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
} 

.flex-column{
    display:flex;
    flex-direction:column;
}

@media (min-width: 576px) {
    .main__container {
        width: 540px;
    }
}
@media (min-width: 768px) {
    .main__container {
        width: 720px;
    }
}
@media (min-width: 992px) {
    .main__container {
        width: 960px;
    }
}
@media (min-width: 1200px) {
    .main__container {
        width: 1140px;
    }
}

.flex__start-center{
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    flex-basis:100%;
}

@media(max-width:850px){
    .flex__start-center{
        flex-basis:auto;
    }
}

.flex__start-between{
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    margin-bottom:25px ;
}

.flex-column{
    display:flex;
    flex-direction:column;
}

.flex-center{
    display:flex;
    justify-content:center;
}

.m-0{
    margin:0;
}

.contact__link{
    color:#22333B!important;
    word-break:break-all;
}

.contact__link:hover{
    color:#22333B;
    text-decoration: underline;
}

/*Pulsante bianco*/

.white__button{
    border: 1px solid #fff;
    color:var(--black-idi);
    background:#fff;
    box-sizing: border-box;
    border-radius: 100px;
    padding-top:14px;
    padding-bottom:14px;
    display:inline-block;
    width:fit-content;
    padding-right:40px;
    padding-left:40px;
    font-weight:bold;
    font-size: 16px;
    line-height: 22px;
    font-weight:800;
    text-decoration:none;
}

@media(max-width:500px){
    .white__button{
        padding-right:30px;
        padding-left:30px;    
    }
}

@media(max-width:390px){
    .white__button{
        padding-right:20px;
        padding-left:20px;    
    }
}

@media(max-width:320px){
    .white__button{
        padding-right:10px;
        padding-left:10px; 
        font-size:14px;  
    }
}

.white__button:hover{
    background:#EF4444;
    color:#fff;
    text-decoration:none;
}

/*Pulsante rosso*/

.red__button{
    background:#EF4444;
    border-radius: 100px;
    color:#fff;
    padding-top:14px;
    padding-bottom:14px;
    display:inline-block;
    width:fit-content;
    padding-right:40px;
    padding-left:40px;
    font-size: 16px;
    line-height: 22px;
    font-weight:800;
    text-decoration:none;
}

@media(max-width:500px){
    .red__button{
        padding-right:30px;
        padding-left:30px;    
    }
}

@media(max-width:390px){
    .red__button{
        padding-right:20px;
        padding-left:20px;    
    }
}

@media(max-width:320px){
    .red__button{
        padding-right:10px;
        padding-left:10px;  
        font-size:14px;  
    }
}


.red__button:hover{
    background: #DF4949;
    color:#fff;
    text-decoration:none;
}

/*Link rosso*/

.idi-arrow__link{
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #EF4444;
    text-decoration:none;
}

.idi-arrow__link:hover{
    color:#DF4949;
}



.s-country__header{
    width:100%;
    background-color:#FAFAFA;
    padding-top:60px;
    padding-bottom:163px;
}

.s-country__details{
    font-size: 24px;
    line-height: 23px;
    letter-spacing: 0.4px;
    font-weight:400;
    margin-left:56px;
}


@media(max-width:850px){
    .s-country__box__small{
        margin-bottom:35px;
        padding-top:25px;
        padding-bottom:25px;
        padding-left:15px;
        padding-right:15px;
        background: #FFFFFF;
        box-shadow: 0px 0px 50px rgba(133, 133, 133, 0.1);
        border-radius: 20px;
    }
}


@media(max-width:500px){
    .s-country__header{
        padding-top:45px;
        padding-bottom:45px;
        height:300px;
    }
    .flex__start-center{
        justify-content:center;
    }
    
}

.s-country__picture{
    height:165px;
    width:165px;
    border-radius:50%;
    margin-right:56px;
}




@media(max-width:500px){
    .s-country__picture{
        height:100px;
        width:100px;
        margin-right:0;
        margin:0 auto;
    }
}

.s-country__name{
    font-weight: 800;
    font-size: 40px;
    line-height: 42px;
    letter-spacing: 0.4px;
    margin-left:56px;
}

@media(max-width:500px){
    .s-country__name{
        font-size: 32px;
        line-height: 42px;
        text-align:center;
        margin-bottom:10px;
        margin-top:25px;
        margin-left:0;
    }
}

@media(max-width:1025px){
    .s-country__detail{
        flex-basis:60%;
    }
}
@media(max-width:500px){
    .s-country__detail{
        display:flex;
        flex-direction:column;
        align-items:center;
        flex-basis:100%;
        margin-left:0;
    }
}




@media(max-width:850px){
    .s-country__details{
        font-size:18px;
    }
}

@media(max-width:500px){
    .s-country__details{
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        margin-top:0;
        margin-bottom:0;
        margin-left:0;
    }
}

.s-country__content{
    margin-top:-91px;
    margin-bottom:60px;
}

.s-country__large{
    display:flex;
    flex-basis:65.78%;
}

@media(max-width:900px){
    .s-country__large{
        flex-basis:100%;
        margin-bottom:35px;
    }
}

.s-country__small{
    display:flex;
    flex-basis:31.57%;
    flex-direction:column;
}

@media(max-width:500px){
    .s-country__small{
        flex-basis:100%;
    }
    .s-country__content{
        margin-bottom:45px;
    }
}

.s-country__box{
    width:100%;
    padding-top:45px;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(133, 133, 133, 0.1);
    border-radius: 20px;
}

@media(max-width:900px){
    .s-country__box{
        padding-top:25px;
    }
}

.box__detail{
    display:flex;
    justify-content:space-between;
    flex-wrap:wrap;
    padding-bottom:35px;
}

@media(max-width:900px){
    .box__detail{
        padding-bottom:25px;
        margin-bottom:25px;
        border-bottom: 1px solid #EFEFEF;
    }

    .last-box__detail{
        border:none;
        margin-bottom:0;
    }
}

.box__title{
    flex-basis:22%;
    padding-right:25px;
    padding-left:25px;
}

.box__description{
    flex-basis:67.33%;
    padding-right:25px;
    padding-left:25px;
}

@media(max-width:900px){
    .box__title , .box__description{
        flex-basis:100%;
        padding-left:15px;
        padding-right:15px;
    }
}

.box__title-name{
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.004em;
    margin-bottom:35px;
}

.box__description-text{
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
    letter-spacing: 0.4px;
    margin-bottom:0;
}


@media(max-width:1200px){
    .box__description-text{
        font-size:14px;
    }
}

@media(max-width:900px){
    .box__description-text{
        font-size:16px;
    }
}

.s-country__box-small{
    width:100%;
    height:auto;
    padding-top:35px;
    padding-left:25px;
    padding-right:25px;
    padding-bottom:35px;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(133, 133, 133, 0.1);
    border-radius: 20px;
    margin-bottom:30px;
}

@media(max-width:900px){
    .s-country__box-small{
        margin-top:-20%;
        margin-bottom:35px;
        padding-top:25px;
        padding-bottom:25px;
        padding-left:15px;
        padding-right:15px;
    }
}

@media(max-width:500px){
    .s-country__box-small{
        margin-top:-37%;
    }
}



.contact__icon{
    color:#EF4444;
    
}

.contact-icon-box{
    border-radius:50%;
    height:30px;
    width:30px;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-right:15px;
    background: rgba(239, 68, 68, 0.03);
}

@media(max-width:900px){
    .box__title-name{
        margin-bottom:25px;
    }
    
}

.sce-contact__icon{
    height:30px;
    width:30px;
    object-fit: cover;
    margin-right:15px;
}

#sce-number, #sce-mail, #sce-website{
    margin:0;
}




.s-country__box-small-report{
    width:100%;
    height:auto;
    padding-top:35px;
    padding-left:25px;
    padding-right:25px;
    padding-bottom:35px;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(133, 133, 133, 0.1);
    border-radius: 20px;
    margin-bottom:30px;
}

.s-country__box-small-article{
    width:100%;
    height:auto;
    padding-top:35px;
    padding-left:25px;
    padding-right:25px;
    padding-bottom:35px;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(133, 133, 133, 0.1);
    border-radius: 20px;
    margin-bottom:30px;
}

.contact__expert-m{
    margin-bottom:25px;
}

@media(max-width:900px){
    .s-country__box-small-report, .s-country__box-small-article{
        margin-bottom:35px;
        padding-top:25px;
        padding-bottom:25px;
        padding-left:15px;
        padding-right:15px;
    }
}


.sce-report__link{
    margin-bottom:25px;
    color:#EF4444;
}

.sce-article__link{
    margin-top:25px;
}

@media(max-width:900px){
    .sce__desktop{
        display:none!important;
    }
}

@media(min-width:901px){
    .sce__mobile{
        display:none!important;
    }
}






