.legislation_footer-container{
    height: auto;
    width: 100%;
    background-color: rgba(239, 68, 68, 0.04);
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 100px;
    padding: 35px 45px;
    border-radius:  0px 0px 20px 20px;
    margin-bottom: 60px;
    margin-top: -60px;
    box-shadow: 0px 0px 50px rgb(133 133 133 / 10%);
}

.footer_link-href{
    display: flex;
    align-items: baseline;
    text-decoration: none;
    color: var(--red);
}
.footer_link-href:hover{
    color: var(--red);
}
.footer_link-href:hover .footer_link-svg{
    margin-left: 18px;
    transition: .3s;
}

.footer_link-title{
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
}

.footer_link-svg{
    width: 14px;
    height: 14px;
    object-fit: cover;
    margin-left: 8px;
    transition: .3s;
}

@media(max-width: 1200px){
    .legislation_footer-container{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 20px;
    }
}

@media(max-width: 768px){
    .legislation_footer-container{
        justify-content: flex-start;
        flex-direction: column;
        padding: 20px 15px;
    }
}