.signup__container{
    display: flex;
    position: relative;
}
.signup__form{
    width: 65%;
}
.form__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 45px;
}

.signup_step-section{
    position: absolute;
    top: 0;
    right: 0;
    background-color: var( --light-grey);
    width: 34%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top:4%;
}

.buttons_container{
    display: flex;
    width: 100%;
    margin-bottom: 45px;
}

.detail-button__container{
    display:flex;
    flex-wrap:wrap;
    margin-top:15px;
    margin-bottom:35px;
}

.detail-button__margin{
    margin-right:20px;
}

@media(max-width: 1024px){
    .signup_step-section{
        display: none;
    }
    .signup__form{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .form__container{
        flex-direction: column;
        align-items: center;
    }
}

.full__width{
    display:flex;
    flex-basis:100%;
}

.full__width p{
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #6F6F6F;
}

.payment-type__link{
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #6F6F6F;
    text-decoration:none;
    margin-right:10px;
}

.payment-type__link:hover{
    color:#EF4444;
}

.detail__button{
    color:#22333B;
    border: 1px solid #22333B;;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    background: white;
    border-radius: 100px;
    padding:6px 20px;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-left:10px;
}

.detail__button:hover{
    color:#22333B;
}

.shopping-cart-flex{
    display:flex;
    justify-content:space-between;
    margin-bottom:25px;
}

.total-box{
    flex-basis:40%;
}

.grey-title{
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #6F6F6F;
    margin-bottom:25px;
}

.reverse{
    flex-direction:row-reverse;
}

.total-value{
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    letter-spacing: 0.4px;
    color: #6F6F6F;
}

.total-title{
    margin-bottom:0;
}

.black{
    color:#22333B;
}

.total-value-big{
    color:#22333B;
    font-size:20px;
    line-height:30px;
    font-weight:bold;
}

.shopping-cart-box{
    width:61%;
}

.bank-transfer__text{
    margin-bottom:40px;
    color:#6F6F6F;
}

.bank-transfer__link{
    color:#6F6F6F;
    text-decoration: underline;
    transition: all .3s;
}
.bank-transfer__link:hover{
    color:#EF4444;
    text-decoration: underline;
}


@media(max-width:850px){
    .detail-button__container{
        justify-content:center;
    }
}

@media (max-width: 500px){
    .buttons_container{
        width:100%;
        align-items: center;
        height: 130px;
    }

    .detail-button__margin{
        margin-right:0px;
        margin-bottom:20px;
    }
}
