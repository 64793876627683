.country-expert__card{
    box-shadow: 0px 4px 50px rgba(133, 133, 133, 0.1);
    border-radius: 20px;
    padding:35px 25px;
    width: 100%;
    height: 371px;
    margin-bottom:30px;
    position: relative;
}

.expertinfo__flex{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    margin-bottom:25px;
    cursor: pointer;
}

.expertcard__img{
    height:60px;
    width:60px;
    border-radius:50%;
    object-fit:cover;
}

.expertcard__info{
    display:flex;
    flex-direction:column;
    align-items:center;
    margin-top: 25px;
}

.expertcard__name{
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.004em;
    color: #22333B;
    margin-bottom:5px;
    margin-top:0;
    text-align: center;
}

.expertcard__data{
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.004em;
    color: #22333B;
    margin-bottom:5px;
    text-transform: uppercase;
    margin-top:0;
}

.expertcard__strong{
    font-weight:700;
}

.expertcard-icons__flex{
    display:flex;
    justify-content:space-evenly;
    position: absolute;
    bottom: 60px;
    width: 80%;
}

.icon__circle{
    height:30px;
    width:30px;
    border-radius:50%;
    background: rgba(239, 68, 68, 0.04);
    display:flex;
    align-items:center;
    justify-content:center;
    color:#EF4444;
    font-size:12px;
}

.red__arrow{
    display: inline-block;
    width: 15px;
    height: 15px;
    position: absolute;
    bottom: 25px;
    right: 25px;
    cursor: pointer;
}

@media(max-width: 1200px){
    .country-expert__card{
        justify-self: center;
    }
}

@media(max-width:600px){
    .country-expert__card{
        margin-bottom:25px;
        padding:35px 25px;
        width: 184px;
        height: 340px;
    }
    .expertcard-icons__flex{
        display: none;
    }
}

@media(max-width:400px){
    .country-expert__card{
        width:90%;
    }
    
}