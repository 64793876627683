.text-input {
    display: flex;
    width: 160px;
    padding: 12px 14px;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    border: 1px solid #DADADA;
    color: #6F6F6F;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
    max-height: 36px;
}

.input-wrapper {
    position: relative;
}

.with-value {
    border: 1px solid #979797 !important;
}

input[type="text"]:focus {
    outline: none;
    border: 1px solid #979797;
}

.input-wrapper img{
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 10px;
    background-color: #FFFFFF;
    cursor: pointer;
}

