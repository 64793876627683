.navbar_login-wrapper{
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    position: relative;
}
.navbar_login-container{
    display: flex;
    border: 1px solid #EF4444;
    box-sizing: border-box;
    border-radius: 31px;
    background: #FFFFFF;
    width: 75px;
    padding: 6px 5px;
    justify-content: space-evenly;
    cursor: pointer;
}


.navbar_login-popup{
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 4px 50px rgba(133, 133, 133, 0.1);
    border-radius: 20px;
    width: 242px;
    height: auto;
    padding: 15px 30px;
    top: 45px;
    margin-top: 15px;
    z-index:9999;
}

.login-popup-content{
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.004em;
    color: #22333B;
    cursor: pointer;
    min-width: 30px;
    border-bottom: 2px solid transparent;
    transition: all .2s linear ;
}
.login-popup-content:hover{
    color: #EF4444;
    border-bottom: 2px solid #EF4444;
}
.login-popup-content-linee{
    border-top: 1px solid #EFEFEF;
    margin: 15px 0px;
}

.counter__container{
    background: #FFFFFF;
    box-shadow: 0px 0px 30px rgba(0, 43, 85, 0.05);
    border-radius: 22px;
    border: 0.5px solid #6F6F6F;
    text-align:center;
    padding-bottom:27px;
    margin-top:39px;
    margin-bottom:79px;
}

.counter__days-left{
    font-style: normal;
    font-weight: 600;
    font-size: 80px;
    line-height: 109px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #EF4444;
}

.counter__days-left-text{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #929292;
}

.counter__title{
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #22333B;
}

.navbar_login-hambuger{
    width: 14px;
    object-fit: contain;
}

@media(max-width:500px){
    .counter__title{
        font-size:20px;
    }
    .navbar_login-container{
        display: flex;
        border: none;
    }
    .navbar_login-hambuger{
        display: none;
    }
    .navbar_login-container{
        width: auto;
    }
}