.user-conference{
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0.4px;
    color: #22333B;
    margin-bottom:35px;
    margin-top:60px;
}

.conference-flex{
    display:flex;
    justify-content:space-between;
    flex-wrap:wrap;
}



.grey-title{
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #6F6F6F;
    margin-bottom:25px;
}

.conference-black-text{
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.004em;
    color: #22333B;
}

.grey-text{
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.4px;
    color: #22333B;
    margin-top:15px;
}

.black-option{
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.004em;
    color: #22333B;
    margin-bottom:15px;
}

.shopping-cart-flex{
    display:flex;
    justify-content:space-between;
    margin-bottom:25px;
}

.shopping-cart-large{
    flex-basis:45%;
}

.shopping-cart-small{
    flex-basis:10%;
}

.cart-item{
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.004em;
    color: #22333B;
}

.discount-code-section{
    margin-top:45px;
}

.discount-form{
    position:relative;
    margin-top:30px;
    margin-bottom:90px;
}

.discount-input{
    width:100%;
    border: 1px solid #979797;
    border-radius: 49px;
    height:54px;
    padding-left:20px;
}

.discount-submit{
    position:absolute;
    right:1%;
    top:13%;
    height:40px;
    width:40px;
    border-radius:50%;
    background-color:#EF4444;
    border:none;
    box-shadow:none;
    cursor:pointer;
}

:focus-visible{
    outline:none;
    box-shadow:none;
}

.tooltip_icon{
    position:absolute;
    top:28%;
    right:33%;
    color:#fff;
    font-size:20px;
    cursor:pointer;
}

.total-box{
    flex-basis:40%;
}

.reverse{
    flex-direction:row-reverse;
}

.total-value{
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    letter-spacing: 0.4px;
    color: #6F6F6F;
}

.total-title{
    margin-bottom:0;
}

.black{
    color:#22333B;
}

.total-value-big{
    color:#22333B;
    font-size:20px;
    line-height:30px;
    font-weight:bold;
}

.buttons-container{
    display:flex;
    justify-content:space-between;
    flex-wrap:wrap;
    margin-top:20px;
}

.shopping-cart-box{
    width:61%;
}

.no-vat-text{
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.4px;
    color: #6F6F6F;
}

@media(max-width:1024px){
    .shopping-cart-box{
        width:100%;
    }
}



@media(max-width:500px){
    .user-conference{
        font-size: 26px;
        line-height: 36px;
        margin-bottom:25px;
    }

    .grey-text{
        margin-bottom:45px;
    }

    .discount-submit{
        right:2%;
    }

    .discount-form{
        margin-bottom:105px;
    }

    .total-box{
        flex-basis:65%;
    }

    
}



