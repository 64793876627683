.login__card-footer{
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;
}

.login__forgot-text__container{
    display:flex;
    justify-content: space-between;
    margin-bottom:45px;
    flex-wrap:wrap;
}

.login__forgot-text__container span{
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.4px;
    color: #929292;
}

.login__forgot-link{
    color:#929292;
}

.login__forgot-link:hover{
    color:#929292;
    text-decoration:underline;
}

.login__create-link{
    color:#EF4444;;
    font-weight:700;
}

.login__create-link:hover{
    color:#EF4444;;
    font-weight:700;
    text-decoration:underline;
}

@media (max-width: 500px){
    .login__card-footer{
        flex-direction: column;
        justify-content: flex-start;
    }

    .login__forgot-text{
        margin-bottom:25px;
    }
}